import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { checkCookieUserAuth } from "../api/auth";

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component }) => {
	return checkCookieUserAuth() ? component : <Navigate replace to="/login" />;
};

export default ProtectedRoute;

type ProtectedRouteProps = {
	component: ReactElement;
};
