import React, { useEffect, useState } from "react";
import { partnersHomeApi, presentationHomeApi, topicsHomeApi, updateTopicsHomeApi } from '../../api/ApiRequest';
import Dropdown from "../Dropdown";
import Presentation from "./presentation";
import Topics from "./topics";
import ReactLoading from 'react-loading';
import ImgSelector from "./imgSelector";

const Home: React.FC = () => {
	const [presentationLoading, setPresentationLoading] = useState<boolean>(false);
	const [presentationData, setPresentationData] = useState<any>({});

	const [topicsLoading, setTopicsLoading] = useState<boolean>(false);
	const [topicsData, setTopicsData] = useState<Array<any>>([]);

	const [partnersLoading, setPartnersLoading] = useState<boolean>(false);
	const [partnersData, setPartnersData] = useState<Array<any>>([]);

	useEffect(() => {
        setPresentationLoading(true);
        presentationHomeApi()
        .then(res => setPresentationData(res.data.data))
        .finally(() => setPresentationLoading(false));
    }, []);

	useEffect(() => {
        setTopicsLoading(true);
        topicsHomeApi()
        .then(res => setTopicsData(res.data.data))
        .finally(() => setTopicsLoading(false));
    }, []);

	useEffect(() => {
        setPartnersLoading(true);
        partnersHomeApi()
        .then(res => setPartnersData(res.data.data))
        .finally(() => setPartnersLoading(false));
    }, []);

	return (
		<div className="p-3 w-full h-screen overflow-auto">
			<h1 className="text-3xl text-blue-400">Accueil</h1>
			<Dropdown title="Présentation" defaultState={true} className="mt-2">
				{!presentationLoading 
					? <Presentation data={presentationData} /> : 
					<div className="w-max flex justify-center items-center p-2">
						<ReactLoading type="spin" color="#4ECAFF" height="20px" width="20px" />
					</div>
				}
			</Dropdown>
			<Dropdown title="Rubriques" className="mt-2">
				{!topicsLoading 
					? <Topics notif="Rubriques enregistrées" data={topicsData} update={updateTopicsHomeApi}/> : 
					<div className="w-max flex justify-center items-center p-2">
						<ReactLoading type="spin" color="#4ECAFF" height="20px" width="20px" />
					</div>
				}
			</Dropdown>
			<Dropdown title="Nos produits populaires" className="mt-2">
				<p>Les produits populaires sont paramétrables depuis <a href="https://bl.nostetourtiere.com" target="_blank" rel="noreferrer" className="text-blue-500 hover:underline">bl.nostetourtiere.com</a>.</p>
			</Dropdown>
			<Dropdown title="Nos partenaires" className="mt-2">
				{!partnersLoading
					? <ImgSelector data={partnersData} /> : 
					<div className="w-max flex justify-center items-center p-2">
						<ReactLoading type="spin" color="#4ECAFF" height="20px" width="20px" />
					</div>
				}
			</Dropdown>
		</div>
	);
};

export default Home;
