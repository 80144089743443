import axios from "./axios";
import Cookies from "js-cookie"

export const checkUserAuth = () => {
    const accessToken = Cookies.get("accessToken");
    if (accessToken !== undefined) {
        const config = {
            headers: {
                "X-NOSTE-TOURTIERE-AUTH-TOKEN":  accessToken
            }
          }
        return axios.get("/auth/check", config)
            .then(() => true)
            .catch(() => false)
    }
    return false;
}

export const checkCookieUserAuth = () => {
    const accessToken = Cookies.get("accessToken");
    return accessToken !== undefined;
}

export const userToken = () => Cookies.get("accessToken")

export const userRefreshToken = () => Cookies.get("refreshToken")

export const userLogout = () => {
    Cookies.remove("accessToken", { path: "/" })
    Cookies.remove("refreshToken", { path: "/" })
}

export const userAuth = (accessToken: string, refreshToken: string = "") => {
    Cookies.set("accessToken", accessToken, { path: "/" })
    Cookies.set("refreshToken", refreshToken, { path: "/" })
}
