import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import routes from "./routes";

const Navigation: React.FC = () => {
	return (
		<Routes>
			{routes.map((item, key) => {
				const element = item.isProtectedRoute ? (
					<ProtectedRoute component={<item.component />} />
				) : (
					<item.component />
				);

				return <Route key={key} path={item.path} element={element} />;
			})}
			<Route path="*" element={<Navigate replace to="/login" />} />
		</Routes>
	);
};

export default Navigation;
