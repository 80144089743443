import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { loginApi } from "../../api/ApiRequest";
import { checkUserAuth, userAuth } from "../../api/auth";
import Logo from "../../assets/images/logo.png";
import { notification } from "../../Notifications";

const Login: React.FC = () => {
	const navigate = useNavigate();
	const [isLogged, setIsLogged] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState<string>("")
    const [isSubmit, setIsSubmit] = useState<boolean>(false)
    const { register, handleSubmit, formState: { errors } } = useForm()

	useEffect(() => {
		if (checkUserAuth()) {
			setIsLogged(true);
			navigate("/")
		} else {
			setIsLogged(false);
		}
	}, [navigate, isLogged]);

    const onSubmit = (data: any) => {
        setIsSubmit(true)
        loginApi(data.email, data.password).then(res => {
            if (res.data.status === "success") {
                userAuth(res.data.data.accessToken, res.data.data.refreshToken)
				notification("Connexion réussie !", "success");
				navigate("/");
            } else {
                if (res.data.message === "invalid credentials") {
                    setErrorMsg("Adresse e-mail ou mot de passe incorrecte.")
                } else {
                    setErrorMsg("Une erreur est survenue. Veuillez réessayer.")
                }
                setIsError(true)
                setIsSubmit(false)
            }
        }).catch(err => {
            if (err.data.message === "invalid credentials") {
                setErrorMsg("Adresse e-mail ou mot de passe incorrecte.")
            } else {
                setErrorMsg("Une erreur est survenue. Veuillez réessayer.")
            }
            setIsSubmit(false)
            setIsError(true)
        })
    }

	if (!isLogged) {
		return (
			<div className="w-full">
				<div className="container w-4/12 sm:w-2/12 mt-4 sm:my-1">
					<img className="w-full" src={Logo} alt="logo" />
				</div>
				<div className="sm:container sm:w-auth px-2 sm:py-1 sm:bg-white sm:shadow-default sm:border sm:border-gray-200 sm:rounded-xl sm:border-gray-200">
					<p className="text-center my-1 sm:my-3 font-medium text-lg">Se connecter à Noste Tourtiere</p>
					<form onSubmit={handleSubmit(onSubmit)} className="px-2">
						<div>
							<Input 
								label="email"
								placeholder="Email"
								type="email"
								register={register}
								errors={errors}
							/>
							{errors.email && <p className="text-sm text-red-500 mb-1 pl-1">Veuillez saisir un email</p>}
						</div>
						<div>
							<Input 
								label="password"
								placeholder="Mot de passe"
								type="password"
								register={register}
								errors={errors}
							/>
							{errors.password && <p className="text-sm text-red-500 mb-1 pl-1">Veuillez saisir un mot de passe</p>}
						</div>
						{isSubmit ? 
							<div className="w-full text-white bg-orange-500 rounded-md font-bold text-xl flex justify-center py-2 mt-2 shadow-md mb-4 cursor-not-allowed">
								<ReactLoading type="spin" color="#fff" height="20px" width="20px" />
							</div>
						: <input className="w-full text-white bg-orange-500 rounded-md font-medium text-xl py-2 mt-2 shadow-md mb-4 cursor-pointer" type="submit" value="Connexion" />}
						{isError && <p className="w-full text-center py-2 px-1 mb-2 rounded-md text-red-500 bg-red-100">{errorMsg}</p>}
					</form>
				</div>
			</div>
		)
	}
	return null;
}

export default Login

const Input: React.FC<InputProps> = ({ label, type, placeholder, register, errors}) => {
	return (
		<input
			className="w-full bg-gray-50 my-1 mt-2 p-4 text-base border border-gray-300 rounded-md font-medium outline-none"
			type={type}
			placeholder={placeholder}
			style={{ borderColor: errors[label] && "#f02849" }}
			{...register(label, { required: true })}
		/>
	)
} 

type InputProps = {
	label: string,
	type: "email" | "password" | "text",
	placeholder: string,
	register: any,
	errors: any
}

