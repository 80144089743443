import axios from "./axios"

// Login
export const loginApi = (email: string, password: string) => axios.post("/auth/login", { email, password });

export const logoutApi = (token: any) => axios.post("/auth/logout", { token });

// Home
export const presentationHomeApi = () => axios.get("/home/presentation");
export const updatePresentationApi = (data: any) => axios.patch("/home/presentation", data);

export const topicsHomeApi = () => axios.get("/home/topics");
export const updateTopicsHomeApi = (data: any) => axios.patch("/home/topics", data);

export const partnersHomeApi = () => axios.get("/home/partners");
export const updatePartnersHomeApi = (data: any) => axios.patch("/home/partners", data);

// Story
export const bannerStoryApi = () => axios.get("/story/banner");
export const updateBannerStoryApi = (data: any) => axios.patch("/story/banner", data);

export const infosStoryApi = () => axios.get("/story/infos");
export const updateInfosStoryApi = (data: any) => axios.patch("/story/infos", data);

// Products
export const infosProductsApi = () => axios.get("/products/infos");
export const updateInfosProductsApi = (data: any) => axios.patch("/products/infos", data);

// Partners
export const partnersFindUsApi = () => axios.get("/findus/partners");
export const updatePartnersFindUsApi = (data: any) => axios.patch("/findus/partners", data);

// Contact
export const infosContactApi = () => axios.get("/contact/infos");
export const updateContactApi = (data: any) => axios.patch("/contact/infos", data);

// Lng & Lat
export const getLngAndLat = (address: string) => fetch(`https://api-adresse.data.gouv.fr/search/?q=${address}`)

// Img
export const getAllImg = () => axios.get("/img");
