import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import GaLogo from "../../assets/images/ga.png"
import GscLogo from "../../assets/images/gsc.png"
import FbLogo from "../../assets/images/fb.png"
import InstaLogo from "../../assets/images/insta.png"
import BlLogo from "../../assets/images/bl.png"
import GmailLogo from "../../assets/images/gmail.png"
import { checkUserAuth, userLogout } from "../../api/auth";
import Modal from "../Modal";

const Menu: React.FC = () => {
	const [minifyMenu, setMinifyMenu] = useState<boolean>(false);
	const [isLogged, setIsLogged] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const navigate = useNavigate();
	const logout = () => {
		document.body.style.overflow = "visible";
		userLogout();
		navigate("/login");
	}

	useEffect(() => {
		if (checkUserAuth()) {
			setIsLogged(true);
		} else {
			setIsLogged(false);
		}
	}, [navigate, isLogged]);

	if (isLogged) {
		return (
			<div className={`${minifyMenu ? "w-max" : "w-96"} h-screen bg-blue-dark text-white overflow-auto`}>
				<div className={`w-full flex items-center border-b-2 border-line-blue ${minifyMenu ? "justify-center py-2" : " justify-between py-4 px-3"}`}>
					{!minifyMenu && <h1 className="text-2xl">Dashboard</h1>}
					<div className="cursor-pointer" onClick={() => setMinifyMenu(!minifyMenu)}>
						<svg className="h-8" viewBox="0 0 24 24"><path d="M5.7 6.71a.996.996 0 0 0 0 1.41L9.58 12L5.7 15.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L7.12 6.71c-.39-.39-1.03-.39-1.42 0z" fill="currentColor"></path><path d="M12.29 6.71a.996.996 0 0 0 0 1.41L16.17 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L13.7 6.7c-.38-.38-1.02-.38-1.41.01z" fill="currentColor"></path></svg>
					</div>
				</div>
				<div className="flex flex-col border-b-2 border-line-blue py-2 px-3">
					{!minifyMenu && <h2 className="text-gray-600 font-normal">nostetourtiere.com</h2>}
					<div className="mt-2 space-y-1">
						<NavigationLink to="/" icon="accueil" minifyMenu={minifyMenu}>
							Accueil
						</NavigationLink>
						<NavigationLink to="/histoire" icon="histoire" minifyMenu={minifyMenu}>
							Histoire
						</NavigationLink>
						<NavigationLink to="/produits" icon="produits" minifyMenu={minifyMenu}>
							Produits
						</NavigationLink>
						<NavigationLink to="/nous-retrouver" icon="nous-retrouver" minifyMenu={minifyMenu}>
							Nous retrouver
						</NavigationLink>
						<NavigationLink to="/contact" icon="contact" minifyMenu={minifyMenu}>
							Contact
						</NavigationLink>
					</div>
					<a href="https://www.nostetourtiere.com" target="_blank" rel="noreferrer" className="mt-2 flex justify-center items-center text-white bg-blue-500 hover:bg-blue-700 font-normal w-full rounded py-2">
						{!minifyMenu && <p className="text-sm font-medium">Consulter le site</p>}
						{minifyMenu && <div className="w-5">
							<svg viewBox="0 0 24 24"><path d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5z" fill="currentColor"></path></svg>
						</div>}
					</a>
				</div>
				<div className="flex flex-col border-b-2 border-line-blue py-2 px-3">
					{!minifyMenu && <h2 className="text-gray-600 font-normal">Analyse</h2>}
					<a href="https://analytics.google.com/analytics/web/#/p301668263/reports/reportinghub" target="_blank" rel="noreferrer" className="mt-2 px-3 flex justify-between items-center text-center text-white font-normal rounded hover:bg-blue-light-hover w-full py-2">
						{!minifyMenu && <div className="flex space-x-3 items-center">
							<div className="w-3">
								<img src={GaLogo} alt="Google Analytics" />
							</div>
							<p className="text-left text-sm font-medium">Google Analytics</p>
						</div>}
						{minifyMenu && <div className="flex">
							<div className="w-5">
								<img src={GaLogo} alt="Google Analytics" />
							</div>
						</div>}
						{!minifyMenu && <div className="text-gray-600">
							<svg className="h-5" viewBox="0 0 24 24"><g className="icon-tabler" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M11 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-5"></path><path d="M10 14L20 4"></path><path d="M15 4h5v5"></path></g></svg>
						</div>}
					</a>
					<a href="https://search.google.com/u/0/search-console?resource_id=sc-domain%3Anostetourtiere.com" target="_blank" rel="noreferrer" className="mt-2 px-3 flex justify-between items-center text-center text-white font-normal rounded hover:bg-blue-light-hover w-full py-2">
						{!minifyMenu && <div className="flex space-x-3 items-center">
							<div className="w-3">
								<img src={GscLogo} alt="Google Search Console" />
							</div>
							<p className="text-left text-sm font-medium">Google Search Console</p>
						</div>}
						{minifyMenu && <div className="flex">
							<div className="w-5">
								<img src={GscLogo} alt="Google Search Console" />
							</div>
						</div>}
						{!minifyMenu && <div className="text-gray-600">
							<svg className="h-5" viewBox="0 0 24 24"><g className="icon-tabler" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M11 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-5"></path><path d="M10 14L20 4"></path><path d="M15 4h5v5"></path></g></svg>
						</div>}
					</a>
				</div>
				<div className="flex flex-col border-b-2 border-line-blue py-2 px-3">
					{!minifyMenu && <h2 className="text-gray-600 font-normal">Réseaux sociaux</h2>}
					<a href="https://www.facebook.com/" target="_blank" rel="noreferrer" className="mt-2 px-3 flex justify-between items-center text-center text-white font-normal rounded hover:bg-blue-light-hover w-full py-2">
						{!minifyMenu && <div className="flex space-x-3 items-center">
							<div className="w-3">
								<img src={FbLogo} alt="Facebook" />
							</div>
							<p className="text-left text-sm font-medium">Facebook</p>
						</div>}
						{minifyMenu && <div className="flex">
							<div className="w-5">
								<img src={FbLogo} alt="Facebook" />
							</div>
						</div>}
						{!minifyMenu && <div className="text-gray-600">
							<svg className="h-5" viewBox="0 0 24 24"><g className="icon-tabler" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M11 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-5"></path><path d="M10 14L20 4"></path><path d="M15 4h5v5"></path></g></svg>
						</div>}
					</a>
					<a href="https://www.instagram.com/" target="_blank" rel="noreferrer" className="mt-2 px-3 flex justify-between items-center text-center text-white font-normal rounded hover:bg-blue-light-hover w-full py-2">
						{!minifyMenu && <div className="flex space-x-3 items-center">
							<div className="w-3">
								<img src={InstaLogo} alt="Instagram" />
							</div>
							<p className="text-left text-sm font-medium">Instagram</p>
						</div>}
						{minifyMenu && <div className="flex">
							<div className="w-5">
								<img src={InstaLogo} alt="Instagram" />
							</div>
						</div>}
						{!minifyMenu && <div className="text-gray-600">
							<svg className="h-5" viewBox="0 0 24 24"><g className="icon-tabler" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M11 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-5"></path><path d="M10 14L20 4"></path><path d="M15 4h5v5"></path></g></svg>
						</div>}
					</a>
				</div>
				<div className="flex flex-col border-b-2 border-line-blue py-2 px-3">
					{!minifyMenu && <h2 className="text-gray-600 font-normal">Configuration</h2>}
					<a href="https://bl.nostetourtiere.com" target="_blank" rel="noreferrer" className="mt-2 px-3 flex justify-between items-center text-center text-white font-normal rounded hover:bg-blue-light-hover w-full py-2">
						{!minifyMenu && <div className="flex space-x-3 items-center">
							<div className="w-3">
								<img src={BlLogo} alt="Bon de livraison" />
							</div>
							<p className="text-left text-sm font-medium">Bon de livraison</p>
						</div>}
						{minifyMenu && <div className="flex">
							<div className="w-5">
								<img src={BlLogo} alt="Bon de livraison" />
							</div>
						</div>}
						{!minifyMenu && <div className="text-gray-600">
							<svg className="h-5" viewBox="0 0 24 24"><g className="icon-tabler" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M11 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-5"></path><path d="M10 14L20 4"></path><path d="M15 4h5v5"></path></g></svg>
						</div>}
					</a>
				</div>
				<div className="flex flex-col border-b-2 border-line-blue py-2 px-3">
					{!minifyMenu && <h2 className="text-gray-600 font-normal">Autre</h2>}
					<a href="https://mail.google.com/mail/u/0/#inbox" target="_blank" rel="noreferrer" className="mt-2 px-3 flex justify-between items-center text-center text-white font-normal rounded hover:bg-blue-light-hover w-full py-2">
						{!minifyMenu && <div className="flex space-x-3 items-center">
							<div className="w-3">
								<img src={GmailLogo} alt="Gmail" />
							</div>
							<p className="text-left text-sm font-medium">Gmail</p>
						</div>}
						{minifyMenu && <div className="flex">
							<div className="w-5">
								<img src={GmailLogo} alt="Gmail" />
							</div>
						</div>}
						{!minifyMenu && <div className="text-gray-600">
							<svg className="h-5" viewBox="0 0 24 24"><g className="icon-tabler" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M11 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-5"></path><path d="M10 14L20 4"></path><path d="M15 4h5v5"></path></g></svg>
						</div>}
					</a>
				</div>
				<div className="my-4 py-2 px-3">
					<button className={`w-full flex justify-center items-center text-center ${!minifyMenu && "border"} p-2 rounded cursor-pointer`} type="button" onClick={() => setShowModal(true)}>
						{!minifyMenu && <p className="text-sm font-medium">Déconnexion</p>}
						{minifyMenu && <div className="w-5">
							<svg viewBox="0 0 24 24"><path d="M21.4 7.5c.8.8.8 2.1 0 2.8l-2.8 2.8l-7.8-7.8l2.8-2.8c.8-.8 2.1-.8 2.8 0l1.8 1.8l3-3l1.4 1.4l-3 3l1.8 1.8m-5.8 5.8l-1.4-1.4l-2.8 2.8l-2.1-2.1l2.8-2.8l-1.4-1.4l-2.8 2.8l-1.5-1.4l-2.8 2.8c-.8.8-.8 2.1 0 2.8l1.8 1.8l-4 4l1.4 1.4l4-4l1.8 1.8c.8.8 2.1.8 2.8 0l2.8-2.8l-1.4-1.4l2.8-2.9z" fill="currentColor"></path></svg>
						</div>}
					</button>
				</div>
				<Modal title="Déconnexion" show={showModal} showModal={setShowModal}>
					<>
						<div className="relative pt-2 flex-auto">
							{/* Header */}
							<p className="py-5 text-lg text-black">Êtes-vous sûr de vouloir vous déconnecter ?</p>
						</div>
						{/* footer */}
						<div className="items-center mb-2 flex">
							<button className="bg-gray-300 cursor-pointer w-full mt-2 mr-2 rounded-md text-sm sm:text-md text-white font-bold py-3 mr-1 dark:bg-gray-900" onClick={() => { setShowModal(false); document.body.style.overflow = "visible" }}>Annuler</button>
							<button className="bg-red-500 cursor-pointer w-full mt-2 ml-2 rounded-md text-sm sm:text-md text-white font-bold py-3" onClick={logout}>Déconnexion</button>
						</div>
					</>
				</Modal>
			</div>
		);
	}
	return null;
};

export default Menu;

const NavigationLink: React.FC<NavigationLinkProps> = ({ to, icon, minifyMenu, children }) => {
	return (
		<NavLink
			to={to}
			className={({ isActive }) => `
            	${isActive ? "bg-blue-light" : "bg-blue-dark font-normal hover:bg-blue-light-hover"} 
				flex items-center text-white text-sm w-full rounded py-2 px-3 font-medium
        	`}
		>
			<NavigationIcon icon={icon} minifyMenu={minifyMenu} />
			{!minifyMenu && <p className="w-max">{children}</p>}
		</NavLink>
	);
};

const NavigationIcon: React.FC<NavigationIconProps> = ({ icon, minifyMenu }) => {
	switch (icon) {
		case "accueil":
			return (
				<svg className={minifyMenu ? "h-6" : "h-5 mr-4"} viewBox="0 0 24 24" >
					<path d="M10 20v-6h4v6h5v-8h3L12 3L2 12h3v8h5z" fill="currentColor"></path>
				</svg>
			);
		case "histoire":
			return (
				<svg className={minifyMenu ? "h-6" : "h-5 mr-4"} viewBox="0 0 24 24" >
					<path d="M19 2l-5 4.5v11l5-4.5V2M6.5 5C4.55 5 2.45 5.4 1 6.5v14.66c0 .25.25.5.5.5c.1 0 .15-.07.25-.07c1.35-.65 3.3-1.09 4.75-1.09c1.95 0 4.05.4 5.5 1.5c1.35-.85 3.8-1.5 5.5-1.5c1.65 0 3.35.31 4.75 1.06c.1.05.15.03.25.03c.25 0 .5-.25.5-.5V6.5c-.6-.45-1.25-.75-2-1V19c-1.1-.35-2.3-.5-3.5-.5c-1.7 0-4.15.65-5.5 1.5V6.5C10.55 5.4 8.45 5 6.5 5z" fill="currentColor"></path>
				</svg>
			);
		case "produits":
			return (
				<svg className={minifyMenu ? "h-6" : "h-5 mr-4"} viewBox="0 0 24 24"><path d="M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25c0-.05.01-.09.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2z" fill="currentColor"></path></svg>
			);
		case "nous-retrouver":
			return (
				<svg className={minifyMenu ? "h-6" : "h-5 mr-4"} viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5A6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5S14 7.01 14 9.5S11.99 14 9.5 14z" fill="currentColor"></path></svg>
			);
		case "contact":
			return (
				<svg className={minifyMenu ? "h-6" : "h-5 mr-4"} viewBox="0 0 24 24"><path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24c1.12.37 2.33.57 3.57.57c.55 0 1 .45 1 1V20c0 .55-.45 1-1 1c-9.39 0-17-7.61-17-17c0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1c0 1.25.2 2.45.57 3.57c.11.35.03.74-.25 1.02l-2.2 2.2z" fill="currentColor"></path></svg>
			);
		case "profil":
			return (
				<svg className={minifyMenu ? "h-6" : "h-5 mr-4"} viewBox="0 0 448 512">
					<path
						d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
						fill="currentColor"
					/>
				</svg>
			);
		default:
			return null;
	}
};

type NavigationLinkProps = {
	to: string,
	icon: string,
	minifyMenu: boolean
}

type NavigationIconProps = {
	icon: string,
	minifyMenu: boolean
}