import Login from "../components/Auth";
import Contact from "../components/Contact";
import FindUs from "../components/FindUs";
import Home from "../components/Home";
import Products from "../components/Products";
import Story from "../components/Story";

const routes = [
	{
		path: "/",
		isProtectedRoute: true,
		component: Home
	},
	{
		path: "/login",
		isProtectedRoute: false,
		component: Login
	},
	{
		path: "/histoire",
		isProtectedRoute: true,
		component: Story
	},
	{
		path: "/produits",
		isProtectedRoute: true,
		component: Products
	},
	{
		path: "/nous-retrouver",
		isProtectedRoute: true,
		component: FindUs
	},
	{
		path: "/contact",
		isProtectedRoute: true,
		component: Contact
	}
];

export default routes;
