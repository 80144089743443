import React, { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { getAllImg, getLngAndLat, updatePartnersFindUsApi } from "../../../api/ApiRequest";
import { v4 as uuidv4 } from "uuid";
import ReactLoading from 'react-loading';
import Modal from "../../Modal";
import { notification } from "../../../Notifications";

const Partners: React.FC<PartnersProps> = ({ data }) => {
    const [loadingAllImg, setLoadingAllImg] = useState<boolean>(false);
    const [allImg, setAllImg] = useState<Array<any>>([]);
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalIndex, setModalIndex] = useState<number>(0);
    const [deleteIndex, setDeleteIndex] = useState<number>(0);
    const [selectedImg, setSelectedImg] = useState<any>([]);
    const { register, setValue, watch, control, reset, handleSubmit, formState: { isDirty, errors } } = useForm({
        defaultValues: {
            contents: data
        }
    });
    const { fields, insert, remove } = useFieldArray(
        {
          control,
          name: "contents"
        }
    );
    const watchFieldArray = watch("contents");
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        };
    });

    const onSubmit = async (data: any) => {
        setIsSubmit(true);
        const dataForm = data.contents;
        const result = new FormData();
        for (let i = 0; i < dataForm.length; i++) {
            if (dataForm[i].img.includes("blob:")) {
                const file = await fetch(dataForm[i].img).then(r => r.blob());
                result.append("files", file);
            } else {
                result.append("files", new Blob());
            }
        }
        result.append("contents", JSON.stringify(dataForm));
        updatePartnersFindUsApi(result)
        .then((res: any) => {
            const data = res.data.data;
            reset({ contents: data });
            notification("Partenaires et marchés enregistrés", "success");
        })
        .finally(() => setIsSubmit(false));
	}

    const handleImg = (item: any, index: number) => {
        setShowModal(true);
        setModalIndex(index);
        setLoadingAllImg(true);
        setSelectedImg({ url: `https://ik.imagekit.io/qipvcstsaar/${item.img}`, path: item.img})
        getAllImg()
        .then(res => {
            const data = res.data.data;
            setAllImg(data);
        })
        .finally(() => setLoadingAllImg(false));
    } 

    const addNewImg = (e: any) => {
        if (e.target.files) {
            const fileArray = Array.from(e.target.files).map((file: any) => {
                return URL.createObjectURL(file)
            })
            const img = {
                url: fileArray[0],
                path: new Date().toString()
            }
            setSelectedImg(img);
            setAllImg([img, ...allImg]);
            Array.from(e.target.files).map((file: any) => URL.revokeObjectURL(file))
        }
    }

    const validatedImg = (index: number) => {
        setShowModal(false); 
        setValue(`contents.${index}.img`, selectedImg.url.replace("https://ik.imagekit.io/qipvcstsaar/", ""), { shouldDirty: true });
        document.body.style.overflow = "visible";
    } 

    const addLngLat = (address: string, index: number) => {
        getLngAndLat(address)
        .then(response => response.json())
        .then(res => {
            const data = res.features
            if (data.length) {
                setValue(`contents.${index}.lng`, data[0].geometry.coordinates[0], { shouldDirty: true });
                setValue(`contents.${index}.lat`, data[0].geometry.coordinates[1], { shouldDirty: true });
            }
        })
    }

    const handleDelete = (index: number) => {
        setDeleteIndex(index);
        setShowDeleteModal(true);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
            <div className="flex">
                <div onClick={() => insert(0, {
                    id: uuidv4(),
                    name: "",
                    address: "",
                    zipCode: 33000,
                    city: "",
                    lat: 0,
                    lng: 0,
                    img: "",
                    description: "",
                    type: "partner",
                    isPresent: false
                })} 
                className="text-blue-500 text-md cursor-pointer">
                    <p>+ Ajouter un lieu au dessus</p>
                </div>
            </div>
            <div className="space-y-5">
                {controlledFields.map((item: any, index) => {
                    let img;
                    if (item.img.length)
                        img = item.img.includes("blob:") ? item.img : `https://ik.imagekit.io/qipvcstsaar/${item.img}`;
                    else
                        img = item.type === "market" ? "https://ik.imagekit.io/qipvcstsaar/Noste_Tourtiere/default.webp"
                        : "https://ik.imagekit.io/qipvcstsaar/Noste_Tourtiere/default_icone_2.png"; 
                    
                    return (
                        <div className="flex justify-between space-x-5 pb-5 border-b border-gray-400" key={item.id}>
                            <div className="w-8/12 flex flex-col space-y-3">
                                <div>
                                    <p className="text-line-blue">Type</p>
                                    <Select
                                        label={`contents.${index}.type`}
                                        register={register}
                                        errors={errors}
                                    />
                                </div>
                                <div>
                                    <p className="text-line-blue">Nom</p>
                                    <Input
                                        label={`contents.${index}.name`}
                                        type="text"
                                        register={register}
                                        errors={errors}
                                    />
                                </div>
                                {item.type === "market" && (
                                    <div>
                                        <p className="text-line-blue">Description</p>
                                        <Textarea
                                            label={`contents.${index}.description`}
                                            register={register}
                                            errors={errors}
                                        />
                                    </div>
                                )}
                                <div>
                                    <p className="text-line-blue">Adresse</p>
                                    <Input
                                        label={`contents.${index}.address`}
                                        type="text"
                                        register={register}
                                        errors={errors}
                                        onChange={() => addLngLat(`${item.address}, ${item.zipCode} ${item.city}`, index)}
                                    />
                                </div>
                                <div>
                                    <p className="text-line-blue">Code postale</p>
                                    <Input
                                        label={`contents.${index}.zipCode`}
                                        type="text"
                                        register={register}
                                        errors={errors}
                                        onChange={() => addLngLat(`${item.address}, ${item.zipCode} ${item.city}`, index)}
                                    />
                                </div>
                                <div>
                                    <p className="text-line-blue">Ville</p>
                                    <Input
                                        label={`contents.${index}.city`}
                                        type="text"
                                        register={register}
                                        errors={errors}
                                        onChange={() => addLngLat(`${item.address}, ${item.zipCode} ${item.city}`, index)}
                                    />
                                </div>
                                <div>
                                    <div className="flex space-x-5">
                                        <div>
                                            <p className="text-line-blue">Longitude</p>
                                            <Input
                                                label={`contents.${index}.lng`}
                                                type="text"
                                                register={register}
                                                errors={errors}
                                            />
                                        </div>
                                        <div>
                                            <p className="text-line-blue">Latitude</p>
                                            <Input
                                                label={`contents.${index}.lat`}
                                                type="text"
                                                register={register}
                                                errors={errors}
                                            />
                                        </div>
                                    </div>
                                    <p className="italic my-1 text-sm text-line-blue">Ces champs se remplissent automatiquement en fonction de l'adresse.</p>
                                </div>
                                {item.type === "market" && (
                                    <div className="form-group form-check flex space-x-5">
                                        <p className="text-line-blue">Présent</p>
                                        <Checkbox 
                                            label={`contents.${index}.isPresent`}
                                            register={register}
                                            errors={errors}
                                        />
                                    </div>
                                )}
                                <div className="flex justify-between">
                                    <div onClick={() => handleDelete(index)} 
                                    className="text-blue-900 text-md cursor-pointer">
                                        <p>- Retirer ce lieu</p>
                                    </div>
                                    <div onClick={() => insert(index+1, {
                                        id: uuidv4(),
                                        name: "",
                                        address: "",
                                        zipCode: 33000,
                                        city: "",
                                        lat: 0,
                                        lng: 0,
                                        img: "",
                                        description: "",
                                        type: "partner",
                                        isPresent: false
                                    })} 
                                    className="text-blue-500 text-md cursor-pointer">
                                        <p>+ Ajouter un lieu en dessous</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-4/12 flex flex-col items-center space-y-1">
                                <div className="w-auto cursor-pointer" onClick={() => handleImg(item, index)}>
                                    <img src={img} alt={item.alt} />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="flex space-x-2">
                {isSubmit ? 
                    <div className="w-max text-white bg-blue-500 rounded flex justify-center items-center px-10 py-2 cursor-not-allowed">
                        <ReactLoading type="spin" color="#fff" height="20px" width="20px" />
                    </div>
                : isDirty ? <input className="w-max text-white bg-blue-500 rounded px-3 py-2 cursor-pointer" type="submit" value="Enregistrer" /> :
                <div className="w-max text-gray-400 bg-gray-100 rounded px-3 py-2 cursor-pointer cursor-not-allowed">
                    <p>Enregistrer</p>
                </div>
                }
            </div>
            <Modal title="Selectionnez une image" show={showModal} showModal={setShowModal} className="w-max">
                <>
                    <div className="relative pt-2">
                        {loadingAllImg && 
                            <div className="w-full flex justify-center items-center p-2">
                                <ReactLoading type="spin" color="#4ECAFF" height="30px" width="30px" />
                            </div>
                        }
                        {!loadingAllImg && 
                            <div className="grid grid-cols-6 grid-flow-row gap-3 pb-3  h-96 overflow-auto">
                                {allImg.map((item, index) => (
                                   <img key={index} src={item.url} alt="img" onClick={() => setSelectedImg(item)} className={`${selectedImg && selectedImg.path === item.path && "border-4 rounded border-blue-600" } object-cover w-24 h-24 cursor-pointer`}/>
                                ))}
                            </div>
                        }
                    </div>
                    {/* footer */}
                    <div className="items-center mb-2 flex">
                        <div className="w-full bg-blue-400 cursor-pointer mt-2 mr-2 rounded-md text-sm sm:text-md text-white font-bold py-3 mr-1 text-center">
                            <input type="file" id="file" className="hidden w-full cursor-pointer" onChange={addNewImg} />
                            <div className="label-holder">
                                <label htmlFor="file" className="w-full cursor-pointer">
                                    Ajouter une image
                                </label>
                            </div>
                        </div>
                        {
                            selectedImg ? <div className="bg-blue-light cursor-pointer  w-full mt-2 ml-2 rounded-md text-sm sm:text-md text-white font-bold py-3 text-center" onClick={() => validatedImg(modalIndex)}>Enregister</div> :
                            <div className="text-gray-400 bg-gray-100 cursor-not-allowed w-full mt-2 ml-2 rounded-md text-sm sm:text-md font-bold py-3 text-center">Enregister</div>
                        }
                    </div>
                </>
            </Modal>
            <Modal title="Retirer ce lieu" show={showDeleteModal} showModal={setShowDeleteModal}>
                <>
                    <div className="relative pt-2 flex-auto">
                        {/* Header */}
                        <p className="py-5 text-lg text-black">Êtes-vous sûr de vouloir retirer ce lieu ?</p>
                    </div>
                    {/* footer */}
                    <div className="items-center mb-2 flex text-center">
                        <div className="bg-gray-300 cursor-pointer w-full mt-2 mr-2 rounded-md text-sm sm:text-md text-white font-bold py-3 mr-1 dark:bg-gray-900" onClick={() => { setShowDeleteModal(false); document.body.style.overflow = "visible" }}>Annuler</div>
                        <div className="bg-red-500 cursor-pointer w-full mt-2 ml-2 rounded-md text-sm sm:text-md text-white font-bold py-3" onClick={() => {remove(deleteIndex); setShowDeleteModal(false); document.body.style.overflow = "visible" }}>Retirer</div>
                    </div>
                </>
            </Modal>
        </form>
    )
}

export default Partners;

const Input: React.FC<InputProps> = ({ label, type, onChange, placeholder, register, errors}) => {
	return (
		<input
			className="w-full mt-1 p-2 text-base border border-gray-300 rounded font-normal outline-none text-gray-700 focus:border-blue-400"
			type={type}
			placeholder={placeholder}
			style={{ borderColor: errors[label] && "#f02849" }}
			{...register(label, { required: true })}
            onChange={onChange}
		/>
	)
} 

const Textarea: React.FC<TextareaProps> = ({ label, register, errors}) => {
	return (
		<textarea
            rows="3"
			className="w-full mt-1 p-2 text-base border border-gray-300 rounded font-normal outline-none text-gray-700 focus:border-blue-400"
			style={{ borderColor: errors[label] && "#f02849" }}
			{...register(label, { required: true })}
		/>
	)
} 

const Checkbox: React.FC<CheckboxProps> = ({ label, register, errors}) => {
	return (
		<input 
            className="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer" 
            type="checkbox"
            id={`flexCheckDefault_${label}`}
            style={{ borderColor: errors[label] && "#f02849" }}
			{...register(label, { required: false })}
        />
	)
} 

const Select: React.FC<SelectProps> = ({ label, register, errors}) => {
	return (
		<select 
            className="w-full mt-1 p-2 border border-gray-300 rounded-sm bg-white focus:outline-none my-1 cursor-pointer" 
            style={{ borderColor: errors[label] && "#f02849" }}
			{...register(label, { required: false })}
        >
            <option value="partner">Partenaire</option>
            <option value="market">Marché</option>
        </select>
	)
} 


type InputProps = {
	label: string,
	type: "email" | "password" | "text",
	placeholder?: string,
	register: any,
	errors: any,
    onChange?: any
}

type TextareaProps = {
    label: string,
    register: any,
	errors: any
}

type CheckboxProps = {
    label: string,
    register: any,
	errors: any,
}

type SelectProps = {
    label: string,
    register: any,
	errors: any,
}

type PartnersProps = {
    data: any
}