import axios from "axios"
import { DEBUG, BASE_URL } from "../utils/Utils"
import { userAuth, userLogout, userRefreshToken, userToken } from "./auth"

const HEADER_AUTH_TOKEN = "X-NOSTE-TOURTIERE-AUTH-TOKEN"

axios.defaults.baseURL = BASE_URL
axios.defaults.headers.common["Content-Type"] = "application/json"
axios.defaults.headers.common.Accept = "application/json"

const updateToken = async (message?: string) => {
    const localRefreshToken = userRefreshToken()
    if (localRefreshToken === null || localRefreshToken === undefined || localRefreshToken === "" || message?.length) {
        userLogout()
        window.location.replace("/login")
    }

    return await axios({
        method: "post",
        url: "/auth/refresh",
        data: {
            token: localRefreshToken
        }
    }).then(res => {
        if(res.status !== 200) {
            userLogout()
            window.location.replace("/login")
        } 
        const { accessToken } = res.data.data
        userAuth(accessToken, localRefreshToken)
        return accessToken
    }).catch((err) => {
        userLogout()
        window.location.replace("/login")
    })
}


// Request
axios.interceptors.request.use(
    config => {
        config.headers[HEADER_AUTH_TOKEN] = userToken()

        if (DEBUG) {
            console.info("➡️ Request ✅", config)
        }
        return config
    },
    error => {
        if (DEBUG) {
            console.error("➡️ Request ️❌", error)
        }
        throw new Error(error)
    }

)

// Response
axios.interceptors.response.use(
    response => {
        if (DEBUG) {
            console.info("⬅️️ Response ✅", response)
        }

        if (response.data.message === "RefreshToken not valid") {
            return updateToken(response.data.message).then(accessToken => {
                response.config.headers[HEADER_AUTH_TOKEN] = accessToken
                return axios.request(response.config)
            }).catch(err => {
                return axios.request(response.config)
            })
        }

        if (response.status === 401 || response.data.message === "Token expired" || response.data.message === "Token not valid") {
            return updateToken().then(accessToken => {
                response.config.headers[HEADER_AUTH_TOKEN] = accessToken
                return axios.request(response.config)
            }).catch(err => {
                return axios.request(response.config)
            })
        }

        return response
    },
    error => {
        if (error.response !== undefined) {
            if (error.response.data.message === "RefreshToken not valid") {
                return updateToken(error.response.data.message).then(accessToken => {
                    error.config.headers[HEADER_AUTH_TOKEN] = accessToken
                    return axios.request(error.config)
                }).catch(err => {
                    return axios.request(error.config)
                })
            }

            if (error.response.status === 401 || error.response.data.message === "Token expired" || error.response.data.message === "Token not valid") {
                return updateToken().then(accessToken => {
                    error.config.headers[HEADER_AUTH_TOKEN] = accessToken
                    return axios.request(error.config)
                }).catch(err => {
                    return axios.request(error.config)
                })
            }

            if (DEBUG) {
                console.info("⬅️️ Response ️❌", error)
            }
        }
        throw error.response
    }
)

export default axios
