import React, { useEffect, useState } from "react";
import Dropdown from "../Dropdown";
import Topics from "../Home/topics";
import ReactLoading from 'react-loading';
import { infosProductsApi, updateInfosProductsApi } from "../../api/ApiRequest";

const Products: React.FC = () => {
	const [infosLoading, setInfosLoading] = useState<boolean>(false);
	const [infosData, setInfosData] = useState<Array<any>>([]);

	useEffect(() => {
        setInfosLoading(true);
        infosProductsApi()
        .then(res => setInfosData(res.data.data))
        .finally(() => setInfosLoading(false));
    }, []);

	return (
		<div className="p-3 w-full h-screen overflow-auto">
			<h1 className="text-3xl text-blue-400">Produits</h1>
			<Dropdown title="Les produits" defaultState={true} className="mt-2">
				<p>Les produits sont paramétrables depuis <a href="https://bl.nostetourtiere.com" target="_blank" rel="noreferrer" className="text-blue-500 hover:underline">bl.nostetourtiere.com</a>.</p>
			</Dropdown>
			<Dropdown title="Informations" className="mt-2">
				{!infosLoading 
					? <Topics notif="Informations enregistrées" data={infosData} update={updateInfosProductsApi}/> : 
					<div className="w-max flex justify-center items-center p-2">
						<ReactLoading type="spin" color="#4ECAFF" height="20px" width="20px" />
					</div>
				}
			</Dropdown>
		</div>
	);
};

export default Products;
