import React, { useState } from "react";

const Dropdown: React.FC<DropdownProps> = ({ title, defaultState, className, children }) => {
    const [show, setShow] = useState<boolean>(defaultState || false);
    return (
        <div className={`w-full ${className}`}>
            <div className="w-full text-line-blue flex justify-between items-center border-b border-gray-400 cursor-pointer hover:bg-gray-100" onClick={() => setShow(!show)}>
                <h2 className="text-xl pl-1">{title}</h2>
                <div className="w-10">
                    {!show && <svg viewBox="0 0 24 24"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6l-6-6l1.41-1.41z" fill="currentColor"></path></svg>}
                    {show && <svg viewBox="0 0 24 24"><path d="M8.12 14.71L12 10.83l3.88 3.88a.996.996 0 1 0 1.41-1.41L12.7 8.71a.996.996 0 0 0-1.41 0L6.7 13.3a.996.996 0 0 0 0 1.41c.39.38 1.03.39 1.42 0z" fill="currentColor"></path></svg>}
                </div>
            </div>
            {show && 
                <div className="pt-3 pb-4 px-2 rounded bg-blue-100">
                    {children}
                </div>
            }
        </div>
    );
};

export default Dropdown;

type DropdownProps = {
    title: string,
    defaultState?: boolean,
    className?: string
}
