import React, { useEffect, useState } from "react";
import Dropdown from "../Dropdown";
import ReactLoading from 'react-loading';
import Partners from "./partners";
import { partnersFindUsApi } from "../../api/ApiRequest";

const FindUs: React.FC = () => {
	const [partnersLoading, setPartnersLoading] = useState<boolean>(false);
	const [partnersData, setPartnersData] = useState<Array<any>>([]);

	useEffect(() => {
        setPartnersLoading(true);
        partnersFindUsApi()
        .then(res => setPartnersData(res.data.data))
        .finally(() => setPartnersLoading(false));
    }, []);

	return (
		<div className="p-3 w-full h-screen overflow-auto">
			<h1 className="text-3xl text-blue-400">Nous retrouver</h1>
			<Dropdown title="Partenaires et marchés" defaultState={true} className="mt-2">
				{!partnersLoading 
					? <Partners data={partnersData} /> : 
					<div className="w-max flex justify-center items-center p-2">
						<ReactLoading type="spin" color="#4ECAFF" height="20px" width="20px" />
					</div>
				}
			</Dropdown>
		</div>
	);
};

export default FindUs;
