import React from "react";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Navigation from "../../navigation/Navigation";
import "react-toastify/dist/ReactToastify.css";
import Menu from "../Menu";

const App: React.FC = () => {
	const { pathname } = useLocation();
	const LeftMenu = () => pathname === "/login" ? null : <Menu />;
	
	return (
		<div>
			<ToastContainer
				position={toast.POSITION.TOP_RIGHT}
				autoClose={2000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				draggable
			/>

			<div className="flex flex-row">
				{LeftMenu()}
				<Navigation />
			</div>
		</div>
	);
};

export default App;
