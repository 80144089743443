export const DEBUG = (process.env.REACT_APP_ENV !== undefined && process.env.REACT_APP_ENV === "dev")

export const BASE_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8082"

export const isMobileSize = (window.innerWidth <= 400)

export const capitalize = (value: string | null | undefined) => {
    if (value === null || value === undefined) {
        return ""
    }
    return value[0].toUpperCase() + value.slice(1)
}

export const darkMode = (theme: string | null) => {
    document.body.classList.add("dark:bg-gray-950")
    if (theme !== null && (theme === "dark" || localStorage.theme === 'dark' || (!(localStorage.getItem("theme")) && window.matchMedia('(prefers-color-scheme: dark)').matches))) {
        document.documentElement.classList.add('dark')
        localStorage.setItem("theme", theme)
        document.documentElement.style.setProperty("--calendarBackground", "#212121")
        document.documentElement.style.setProperty("--calendarBackgroundChild", "#4b4b4b")
    } else {
        document.documentElement.classList.remove('dark')
        localStorage.setItem("theme", "light")
        document.documentElement.style.setProperty("--calendarBackground", "#FFFFFF")
        document.documentElement.style.setProperty("--calendarBackgroundChild", "#ededed")
    }
}