import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ReactLoading from 'react-loading';
import { getAllImg, updatePresentationApi } from "../../../api/ApiRequest";
import { notification } from "../../../Notifications";
import Modal from "../../Modal";

const Presentation: React.FC<PresentationProps> = ({ data }) => {
    const [loadingAllImg, setLoadingAllImg] = useState<boolean>(false);
    const [selectedImg, setSelectedImg] = useState<any>({ url: `https://ik.imagekit.io/qipvcstsaar/${data.img}`, path: data.img});
    const [allImg, setAllImg] = useState<Array<any>>([]);
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
	const { register, getValues, setValue, reset, handleSubmit, formState: { isDirty, errors } } = useForm({
        defaultValues: {
            title: data.title,
            subTitle: data.subTitle,
            slogan: data.slogan,
            img: `https://ik.imagekit.io/qipvcstsaar/${data.img}`,
            alt: data.alt
        }
    });

	const onSubmit = async (data: any) => {
        setIsSubmit(true);
        const result = new FormData()
        result.append("title", data.title)
        result.append("subTitle", data.subTitle)
        result.append("slogan", data.slogan)
        result.append("alt", data.alt)
        if (data.img.includes("blob:")) {
            const file = await fetch(data.img).then(r => r.blob());
            result.append("file", file)
        }
        else {
            result.append("img", data.img.replace("https://ik.imagekit.io/qipvcstsaar/",  ""))
        }
        updatePresentationApi(result)
        .then(res => {
            const data = res.data.data;
            reset({
                title: data.title,
                subTitle: data.subTitle,
                slogan: data.slogan,
                img: `https://ik.imagekit.io/qipvcstsaar/${data.img}?tr=q-5`,
                alt: data.alt
            });
            notification("Présentation enregistrée", "success");
        })
        .finally(() => setIsSubmit(false));
	}

    const handleImg = () => {
        setShowModal(true);
        setLoadingAllImg(true);
        getAllImg()
        .then(res => {
            const data = res.data.data;
            setAllImg(data);
        })
        .finally(() => setLoadingAllImg(false));
    } 

    const validatedImg = () => {
        setShowModal(false); 
        setValue("img", selectedImg.url, { shouldDirty: true });
        document.body.style.overflow = "visible";
    } 

    const addNewImg = (e: any) => {
        if (e.target.files) {
            const fileArray = Array.from(e.target.files).map((file: any) => {
                return URL.createObjectURL(file)
            })
            const img = {
                url: fileArray[0],
                path: new Date().toString()
            }
            setSelectedImg(img);
            setAllImg([img, ...allImg]);
            Array.from(e.target.files).map((file: any) => URL.revokeObjectURL(file))
        }

    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex justify-between space-x-5">
            <div className="w-8/12 flex flex-col space-y-3">
                <div>
                    <p className="text-line-blue">Titre principale</p>
                    <Input
                        label="title"
                        type="text"
                        register={register}
                        errors={errors}
                    />
                </div>
                <div>
                    <p className="text-line-blue">Titre secondaire</p>
                    <Input
                        label="subTitle"
                        type="text"
                        register={register}
                        errors={errors}
                    />
                </div>
                <div>
                    <p className="text-line-blue">Slogan</p>
                    <Input
                        label="slogan"
                        type="text"
                        register={register}
                        errors={errors}
                    />
                </div>
                <div className="flex space-x-2">
                    {isSubmit ? 
                        <div className="w-max text-white bg-blue-500 rounded flex justify-center items-center mt-3 px-10 py-2 cursor-not-allowed">
                            <ReactLoading type="spin" color="#fff" height="20px" width="20px" />
                        </div>
                    : isDirty ? <input className="w-max text-white bg-blue-500 rounded mt-3 px-3 py-2 cursor-pointer" type="submit" value="Enregistrer" /> :
                    <div className="w-max text-gray-400 bg-gray-100 rounded mt-3 px-3 py-2 cursor-pointer cursor-not-allowed">
                        <p>Enregistrer</p>
                    </div>
                    }
                </div>
            </div>
            <div className="w-4/12 flex flex-col items-center space-y-1">
                <div className="w-auto cursor-pointer" onClick={handleImg}>
                    <img src={getValues("img")} alt="Tourtière" />
                </div>
            </div>
            <Modal title="Selectionnez une image" show={showModal} showModal={setShowModal} className="w-max">
                <>
                    <div className="relative pt-2">
                        {loadingAllImg && 
                            <div className="w-full flex justify-center items-center p-2">
                                <ReactLoading type="spin" color="#4ECAFF" height="30px" width="30px" />
                            </div>
                        }
                        {!loadingAllImg && 
                            <div className="grid grid-cols-6 grid-flow-row gap-3 pb-3  h-96 overflow-auto">
                                {allImg.map((item, index) => (
                                   <img key={index} src={item.url} alt="img" onClick={() => setSelectedImg(item)} className={`${selectedImg && selectedImg.path === item.path && "border-4 rounded border-blue-600" } object-cover w-24 h-24 cursor-pointer`}/>
                                ))}
                            </div>
                        }
                    </div>
                    {/* footer */}
                    <div className="items-center mb-2 flex">
                        <div className="w-full bg-blue-400 cursor-pointer mt-2 mr-2 rounded-md text-sm sm:text-md text-white font-bold py-3 mr-1 text-center">
                            <input type="file" id="file" className="hidden w-full cursor-pointer" onChange={addNewImg} />
                            <div className="label-holder">
                                <label htmlFor="file" className="w-full cursor-pointer">
                                    Ajouter une image
                                </label>
                            </div>
                        </div>
                        {
                            selectedImg ? <div className="bg-blue-light cursor-pointer  w-full mt-2 ml-2 rounded-md text-sm sm:text-md text-white font-bold py-3 text-center" onClick={validatedImg}>Enregister</div>:
                            <div className="text-gray-400 bg-gray-100 cursor-not-allowed w-full mt-2 ml-2 rounded-md text-sm sm:text-md font-bold py-3 text-center">Enregister</div>
                        }
                    </div>
                </>
            </Modal>
        </form>
    );
}

export default Presentation;

const Input: React.FC<InputProps> = ({ label, type, placeholder, register, errors}) => {
	return (
		<input
			className="w-full mt-1 p-2 text-base border border-gray-300 rounded font-normal outline-none text-gray-700 focus:border-blue-400"
			type={type}
			placeholder={placeholder}
			style={{ borderColor: errors[label] && "#f02849" }}
			{...register(label, { required: true })}
		/>
	)
} 

type InputProps = {
	label: string,
	type: "email" | "password" | "text",
	placeholder?: string,
	register: any,
	errors: any
}

type PresentationProps = {
    data: any
}